body {
  --surface-01: #2a2a2a;
  --surface-02: #202020;
  --surface-03: #303030;
  --surface-04: hsla(0, 0%, 100%, 0.2);
  --font-01: #fff;
  --font-02: #ddd;
  --font-03: #9c9c9c;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
  justify-content: space-between;
  border-radius: 0.4rem;
  overflow: hidden;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background-color: var(--surface-01);
  border: 5px solid var(--surface-01) !important;
  flex: 1;
  padding: 0;
  margin: 0 !important;
  border-radius: 0 !important;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-btn {
  width: 100%;
  text-align: center;
  padding: 8px 16px;
  color: #fff;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  color: inherit;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: inherit;
  background-color: #0c3;
  border-radius: 0.4rem;
}
